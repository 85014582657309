// i18next-extract-mark-ns-start about-us-careers

import { LINKS } from '@utils/constants';
import { ICareerFrontmatter } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Deck from '@components/Deck';
import Career from '@components/Career';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CareerPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__careers" layout="wide">
				<Trans i18nKey="intro">
					<h2>Enhance your career</h2>
					<p>
						Realis Simulation offers a broad range of careers for
						talented and driven individuals, from new graduates to
						experienced professionals.
					</p>
				</Trans>
				<Deck>
					{data.careers.edges.filter(({ node }: { node: ICareerFrontmatter }, i: number) => !node.frontmatter.unpublish).map(
						({ node }: { node: ICareerFrontmatter }, i: number) => (
							<Career
								key={i}
								title={node.frontmatter.title}
								summary={node.frontmatter.summary}
								image={node.frontmatter.image}
								link={`${LINKS.ABOUT_CAREERS}${node.fields.slug}`}
								unpublish={''} />
						)
					)}
				</Deck>
			</PageSection>
		</Page>
		// Watch this space to find out more about exciting careers at Realis Simulation
	);
};

export default CareerPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["about-us-careers", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/careers/Realis-Simulation_Careers.jpg" }) {
		...imageBreaker
	}
	careers: allMarkdownRemark(
		filter: { 
			fileAbsolutePath: { regex: "/careers/" } 
			fields: { language: { eq: $language } }
		}
	) {
		edges {
			node {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					unpublish
				}
			}
		}
	}
}
`;

